var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.absolute ? 'absolute server-status' : 'server-status'},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-progress-circular',_vm._g(_vm._b({attrs:{"rotate":360,"size":40,"width":5,"value":_vm.server.cpu,"color":_vm.getProgressColor(_vm.server.cpu)}},'v-progress-circular',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.getProgressColor(_vm.server.cpu)}},[_vm._v("mdi-memory")])],1)]}}])},[_c('b',[_vm._v("CPU Usage:")]),_vm._v(" "+_vm._s(_vm.server.cpu)+"% ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-progress-circular',_vm._g(_vm._b({staticClass:"stat-percent",attrs:{"rotate":360,"size":40,"width":5,"value":_vm.server.mem,"color":_vm.getProgressColor(_vm.server.mem)}},'v-progress-circular',attrs,false),on),[_c('v-icon',{attrs:{"size":"17","color":_vm.getProgressColor(_vm.server.mem)}},[_vm._v("mdi-server")])],1)]}}])},[_c('b',[_vm._v("Memory Usage:")]),_vm._v(" "+_vm._s(_vm.server.mem)+"% ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-progress-circular',_vm._g(_vm._b({staticClass:"stat-percent",attrs:{"rotate":360,"size":40,"width":5,"value":_vm.server.drive,"color":_vm.getProgressColor(_vm.server.drive)}},'v-progress-circular',attrs,false),on),[_c('v-icon',{attrs:{"size":"19","color":_vm.getProgressColor(_vm.server.drive)}},[_vm._v("mdi-harddisk")])],1)]}}])},[_c('b',[_vm._v("Disk Usage:")]),_vm._v(" "+_vm._s(_vm.server.drive)+"% ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }